export function hasDuplicateTiers(existingTiers, newTier) {
    if (existingTiers && newTier) {
        const isDuplicate = existingTiers.some((existingTier) => {
            if (existingTier.loyaltyTierId === newTier.loyaltyTierId) {
                return false;
            }
    
            return (
                existingTier.name.toLowerCase() === newTier.name.toLowerCase() ||
                parseInt(existingTier.points) === parseInt(newTier.points)
            );
        });
        return isDuplicate;
    }

    const uniqueNames = new Set();
    const uniquePoints = new Set();

    for (const existingTier of existingTiers) {
        if (uniqueNames.has(existingTier.name)) {
            return true;
        }
        uniqueNames.add(existingTier.name);

        if (uniquePoints.has(parseInt(existingTier.points))) {
            return true;
        }
        uniquePoints.add(parseInt(existingTier.points));
    }

    // No duplicates found
    return false;
}
