import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box className="footer">
            <Typography className="footer-copyright">
                &copy; Philip Morris Products S.A.
            </Typography>
        </Box>
    );
};

export default Footer;
