import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import sessionStorageMiddleware from './sessionStorageMiddleware';
import { getSessionStorage } from '../services/storageUtils';

const logActions = process.env.REACT_APP_LOG_ACTIONS === 'true';

const logger = createLogger({
    predicate: (getState, action) => action.type && logActions,
});

export default function configureStore() {
    const persistedState = getSessionStorage('reduxState')
    ? JSON.parse(getSessionStorage('reduxState'))
    : {};
    return createStore(
        rootReducer,
        persistedState,
        composeWithDevTools(applyMiddleware(thunk, logger, sessionStorageMiddleware))
    );
}
