import { useState, useEffect } from 'react';

import ConfirmDialog from '../../common/ConfirmDialog';
import * as C from '../../../services/constants';

import closeIcon from '../../../resources/images/close.svg';

const EventDetails = ({ status, type, event, onClose }) => {
    const [eventInfo, setEventInfo] = useState({ ...event } || {});
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const handleConfirm = () => {
        // call publish api
    };
    const handleChange = (e) => {
        const { value, name } = e.target;
        const updatedValue = value !== undefined ? value : '';
        setEventInfo({ ...eventInfo, [name]: updatedValue });
    };
    const handleClick = () => {
        setShowConfirmDialog(true);
    };
    useEffect(() => {
        // render logic for tier details popup
    }, [showConfirmDialog]);
    return (
        <div className="event-details-dialog">
            {showConfirmDialog ? (
                <ConfirmDialog
                    title="Confirm: Publish Event Activity!"
                    message="Are you sure you want to publish this new event activity to your loyalty program?"
                    note="Once you publish here you can not edit or update this event activity. "
                    confirmText="Yes, Publish Event"
                    backText="No, Continue Editing"
                    onClose={() => setShowConfirmDialog(false)}
                    onConfirm={handleConfirm}
                />
            ) : (
                <div className="event-details">
                    <div className="event-header">
                        <h2 className="event-title">
                            {type === 'new' ? 'Add Event' : 'View Event'}
                        </h2>
                        <img
                            src={closeIcon}
                            alt="close dialog"
                            className="close-event-dialog"
                            onClick={onClose}
                        />
                    </div>
                    <div className="event-details-form">
                        <div className="event-info">
                            <div className="event-name">
                                <label>Event Activity Name: </label>
                                <input
                                    type="text"
                                    placeholder="e.g. Register"
                                    name="name"
                                    value={eventInfo.name}
                                    onChange={(e) => handleChange(e)}
                                    disabled={type === C.EVENT_TYPE_PUBLISHED ? true : false}
                                />
                            </div>
                        </div>
                        <div className="event-description">
                            <label>Event Activity Description: </label>
                            <input
                                type="text"
                                placeholder="e.g. User Registration"
                                name="description"
                                value={eventInfo.description}
                                onChange={(e) => handleChange(e)}
                                disabled={type === C.EVENT_TYPE_PUBLISHED ? true : false}
                            />
                        </div>
                    </div>

                    {/* {type !== 'published' && (
                        <div className="event-detials-cta-container">
                            <button onClick={() => handleClick()}>Publish Event</button>
                            <button>Save as Draft</button>
                            <button>Delete</button>
                        </div>
                    )} */}
                </div>
            )}
        </div>
    );
};

export default EventDetails;
