import { v4 as uuidv4 } from 'uuid';
import { getIdToken } from '../authUtils';
import { ApiError } from '../errors';

export const API_NAME = 'loyalty-admin-portal-api';

export const getConfig = async (body = undefined) => {
    return {
        headers: {
            Authorization: await getIdToken(),
            'X-Correlation-Id': uuidv4(),
            'X-Channel': 'WEBSITE',
        },
        body,
    };
};
export const validateResponse = (resp) => {
    return resp;
};
export const handleError = (err) => {
    console.error('LOYALTY_API error:', err);
    throw new ApiError(err);
};
