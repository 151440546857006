import { API } from 'aws-amplify';
import { API_NAME, getConfig, validateResponse, handleError } from './apiUtils';

export function getEventList(market, category, brand) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/event-types`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export function getRuleList(market, category, brand) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/rule`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function getRule(market, category, brand, ruleId) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/rule/${ruleId}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function createRule(market, category, brand, body) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/rule`;
    return getConfig(body)
        .then((config) => API.post(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function updateRule(market, category, brand, ruleId, body) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/rule/${ruleId}`;
    return getConfig(body)
        .then((config) => API.put(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function deleteRule(market, category, brand, ruleId) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/rule/${ruleId}`;
    return getConfig()
        .then((config) => API.del(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}
