import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    programInfo: {},
    step: 0,
    assignTiers: false,
    manageProgram: {},
};
const programReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PROGRAM_SET_STEP: {
            return {
                ...state,
                step: action.step,
            };
        }
        case types.PROGRAM_ASSIGN_TIERS: {
            return {
                ...state,
                assignTiers: action.assignTiers,
            };
        }
        case types.PROGRAM_STORE_INFO: {
            return {
                ...state,
                programInfo: action.programInfo,
            };
        }
        default: {
            return state;
        }
    }
};

export default programReducer;
