import { API } from 'aws-amplify';
import { API_NAME, getConfig, validateResponse, handleError } from './apiUtils';

export async function getTierList(market, category, brand) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/tier`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function saveTierList(market, category, brand, body) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/save-tiers`;
    return getConfig(body)
        .then((config) => API.post(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function getTier(market, category, brand, tierId) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/tier/${tierId}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function createTier(market, category, brand, body) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/tier`;
    return getConfig(body)
        .then((config) => API.post(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function updateTier(market, category, brand, tierId, body) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/tier/${tierId}`;
    return getConfig(body)
        .then((config) => API.put(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function deleteTier(market, category, brand, tierId) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/tier/${tierId}`;
    return getConfig()
        .then((config) => API.del(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}
