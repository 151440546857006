import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Paper } from '@mui/material';

import { signIn } from '../../../services/authUtils';

import PMILogo from '../../../resources/images/pmi-logo.svg';

const LoginPage = () => {
    const { loading } = useSelector((state) => state.auth);
    const handleLogin = () => {
        signIn();
    };

    return (
        <div className="login-container">
            <Paper className="login-component">
                <img
                    src={PMILogo}
                    alt="Philip Morris International"
                    width="516px"
                    height="auto"
                />
                <h2 className="login-title">
                    <span>Hello!</span> Welcome
                </h2>

                {loading ? (
                    <CircularProgress sx={{ mt: 5, mb: 5 }} />
                ) : (
                    <>
                        <p className="login-description">
                            Please login to access this site
                        </p>
                        <button className="login-btn" onClick={handleLogin}>
                            Log In
                        </button>
                    </>
                )}
            </Paper>
        </div>
    );
};

export default LoginPage;
