import React, { useMemo, useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { brandLabels } from '../../services/labelUtils';

function BrandDropdown({ brands, brand, onChange }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const icon = open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

    const options = useMemo(() => {
        return brands.sort();
    }, [brands]);

    const hasOptions = options.length > 1;
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (brand) => {
        setAnchorEl(null);
        onChange(brand);
    };

    return !hasOptions ? (
        <Typography sx={{ pr: 1, mx: 1 }}>{brandLabels[brand]}</Typography>
    ) : (
        <>
            <Button
                onClick={handleOpen}
                endIcon={icon}
                sx={{
                    color: 'inherit',
                    textTransform: 'none',
                    mx: 1,
                }}
            >
                <Typography>{brandLabels[brand]}</Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {options.map((item) => (
                    <MenuItem key={item} onClick={() => handleClick(item)}>
                        {brandLabels[item]}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default BrandDropdown;
