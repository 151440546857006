import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    isOpen: false,
    severity: null,
    title: null,
    message: null,
};

export default function notificationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.NOTIFICATION_OPEN: {
            return {
                isOpen: true,
                severity: action.severity || null,
                title: action.title || null,
                message: action.message || null,
            };
        }

        case types.NOTIFICATION_CLOSE: {
            return {
                isOpen: false,
                severity: null,
                title: null,
                message: null,
            };
        }

        default:
            return state;
    }
}
