import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Alert, Typography } from '@mui/material';
import { notificationClose } from '../../store/actions/notificationActions';

const DURATION = {
    DEFAULT: 4000,
    success: 3000,
};

const Notification = () => {
    const dispatch = useDispatch();

    const notification = useSelector((state) => state.notification);
    const { isOpen, severity, title, message } = notification;

    const duration = DURATION[severity] || DURATION.DEFAULT;

    const handleClose = () => {
        dispatch(notificationClose());
    };

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={duration}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleClose}
            sx={{ maxWidth: '300px', mb: '5%' }}
        >
            <Alert
                severity={severity || 'info'}
                onClose={handleClose}
                icon={false}
                sx={{
                    py: 1,
                    px: 1.5,
                    width: '100%',
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    borderLeft: `5px solid ${
                        severity === 'error' ? '#C8102E' : severity === 'success' ? '#46DB8C' : '#1D5B9F'
                    }`,
                }}
            >
                <Typography sx={{ fontSize: '0.8rem', pr: 1, fontWeight: 'bold' }}>
                    {title}
                </Typography>
                <Typography sx={{ fontSize: '0.8rem', pr: 1 }}>
                    {message}
                </Typography>
            </Alert>
        </Snackbar>
    );
};

export default Notification;
