import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { useAuthListener } from './hooks/useAuthListener';
import LoginPage from './components/pages/loginPage/LoginPage';
import Header from './components/common/Header';
import HomePage from './components/pages/homePage/HomePage';
import AuthRequired from './components/common/AuthRequired';
import CreateProgram from './components/pages/createProgram/CreateProgram';
import ManageRules from './components/pages/manageRules/ManageRules';
import ManageTiers from './components/pages/manageTiers/ManageTiers';
import ManageEvents from './components/pages/manageEvents/ManageEvents';
import Help from './components/pages/help/Help';
import Notification from './components/common/Notification';
import Spinner from './components/common/Spinner';
import Footer from './components/common/Footer';
import theme from './styles';

function App() {
    useAuthListener();
    const { loading, user, error } = useSelector((state) => state.auth);
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <CssBaseline />
                <Spinner />
                <Notification />
                {!user ? (
                    <LoginPage />
                ) : (
                    <div>
                        <Header />
                        <Routes>
                            <Route element={<AuthRequired />}>
                                <Route exact path="/" element={<HomePage />} />
                                <Route
                                    path="/create-program"
                                    element={<CreateProgram />}
                                />
                                <Route
                                    path="/manage-tiers"
                                    element={<ManageTiers />}
                                />
                                <Route
                                    path="/manage-events"
                                    element={<ManageEvents />}
                                />
                                <Route
                                    path="/manage-rules"
                                    element={<ManageRules />}
                                />
                                <Route path="/help" element={<Help />} />
                            </Route>
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                        <Footer />
                    </div>
                )}
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
