import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material';

import DefineTiers from './DefineTiers';
import WizardInstructions from './WizardInstructions';
import DefineRules from './DefineRules';
import DefinePointSource from './DefinePointSource';
import {
    hasSideBar,
    updateCurrentComponent,
} from '../../../store/actions/navActions';
import { setStep } from '../../../store/actions/programActions';
import * as C from '../../../services/constants';

import helpIcon from '../../../resources/images/help-circle.svg';
import launcherIcon from '../../../resources/images/launcher-icon.svg';

const steps = [
    'Define Point Source',
    'Define Tiers',
    'Set Loyalty Rules',
    'Go Live!',
];

export const WizardConfirmation = () => {
    const dispatch = useDispatch();
    const handleHomeRedirection = () => {
        dispatch(setStep(0));
    };
    return (
        <div className="wizard-confirmation-container">
            <div className="wizard-confirmation">
                <div className="confirmation-header">
                    <img src={launcherIcon} alt="launcher icon" />
                    <h1>Congratulations!</h1>
                </div>
                <p>Your Loyalty Program is now live!</p>
                <Link to="/" onClick={handleHomeRedirection}>
                    <button>Go Back Home</button>
                </Link>
            </div>
        </div>
    );
};

const CreateProgram = () => {
    const dispatch = useDispatch();
    const { step } = useSelector((state) => state.program);

    const [showInstruction, setShowInstruction] = useState(true);
    const [wizardInitiated, setWizardInitiated] = useState(false);

    const handleStepChange = (stepIndex) => {
        const stepsLength = steps.length;
        if (!(stepIndex > step || step === stepsLength - 1)) {
            dispatch(setStep(stepIndex));
        }
    };

    useEffect(() => {
        dispatch(updateCurrentComponent(C.APP_CREATE_PROGRAM_WIZARD_TITLE));
        dispatch(hasSideBar(false));
    });

    return (
        <Box className="create-program-container">
            {showInstruction ? (
                <WizardInstructions
                    visible={setShowInstruction}
                    initiated={wizardInitiated}
                    setInitiated={setWizardInitiated}
                />
            ) : (
                <>
                    <Stepper activeStep={step} alternativeLabel>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton
                                    onClick={() => handleStepChange(index)}
                                >
                                    <StepLabel>{label}</StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <p
                        className="show-wizard-instructions"
                        onClick={() => setShowInstruction(true)}
                    >
                        <img src={helpIcon} />
                        How to Set Loyalty Program Rules
                    </p>
                    {step === 0 && <DefinePointSource />}
                    {step === 1 && <DefineTiers />}
                    {step === 2 && <DefineRules />}
                    {step === 3 && <WizardConfirmation />}
                </>
            )}
        </Box>
    );
};

export default CreateProgram;
