// Notification actions

import * as types from './actionTypes';

export function notificationShow(title, message, severity) {
    return { type: types.NOTIFICATION_OPEN, title, message, severity };
}

export function notificationClose() {
    return { type: types.NOTIFICATION_CLOSE };
}

export function notifySuccess(title, message) {
    return notificationShow(title, message, 'success');
}

export function notifyError(title, message) {
    return notificationShow(title, message, 'error');
}

export function notify(title, message){
    return notificationShow(title, message, 'info')
}