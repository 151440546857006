export const faqList = [
    {
        question:
            'Why can I not see other options in the ‘Country (Indonesia)’, ‘Product (Conventional Cigarettes)’ & ‘Brand (Marlboro)’ category options in the top navigation drop down?',
        answer: [
            'You are not authorised to access those options by your admin. If you still need to get access please reach out to your market admin offline to request access.',
        ],
    },
    {
        question: 'How to change the tier ranking or sequence order?',
        answer: [
            'At the time of creating a new loyalty program, you can add, edit and delete new tiers.',
            'The sequence of input fields for the tier details on the ‘Create Tiers’ page is a default ranking or order of tiers.',
            'Once you publish or ‘Go Live’ with your loyalty program you can only add new tiers. You can not change the ranking or order of the existing tiers.',
        ],
    },
    {
        question:
            'Can I delete or edit a tier after the Loyalty Program has gone live?',
        answer: [
            'At the time of creating a new loyalty program, you can add, edit and delete new tiers.',
            'Once you publish or ‘Go Live’ with your loyalty program you can only add new tiers.',
            'You can not update or delete existing tiers.',
        ],
    },
    {
        question: 'Is there any specific sequence order for Rules?',
        answer: [
            'No, the rules can be created in any order or sequence. However the rules engine will always scan for and pick up the 1st rule created for an event activity.',
        ],
    },
    {
        question: 'Can I edit, update or delete a rule after it has gone live?',
        answer: [
            'Once you publish the rule you can only expire a rule.',
            'You <b>can not</b> edit, update or delete an existing rule.',
        ],
    },
    {
        question:
            'What will happen if two rules are published for the same event activity?',
        answer: [
            'If there are two rules for the same event activity then, the system will pick the first record from the list of rules and ignore the second one that is created later as the rule engine found the first matching rule, it will execute that and ignore all others after that.',
            'If you need the system to pick the second rule then you must expire the first rule.',
        ],
    },
    {
        question:
            'I made a mistake while publishing the New Loyalty Program, tiers or rule. How can I correct it?',
        answer: [
            'Please raise a ticket to CC Technical Support on Qbranch <a href="https://pmicit.service-now.com/qbranch">here</a>.',
            'Don\'t know how to raise a CC support ticket? visit <a href="https://dce-confluence.app.pconnect.biz/display/CCRRPMERGE/Raising+an+Incident">this page</a>.']
    },
];
