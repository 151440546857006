import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
    createLoyaltyProgram,
    getLoyaltyProgram,
} from '../../../services/api/programApi';
import { getCreateLoyaltyProgramPayload } from '../../../services/api/apiPayloads';
import {
    hasSideBar,
    updateCurrentComponent,
} from '../../../store/actions/navActions';
import {
    hideLoading,
    showLoading,
} from '../../../store/actions/loadingActions';
import { storeProgramInfo } from '../../../store/actions/programActions';
import { notifyError } from '../../../store/actions/notificationActions';
import * as C from '../../../services/constants';

import customerServiceActive from '../../../resources/images/customer-service-active.svg';
import customerServiceInactive from '../../../resources/images/customer-service-inactive.svg';
import externalLinkActive from '../../../resources/images/external-link-active.svg';
import externalLinkInactive from '../../../resources/images/external-link-inactive.svg';
import manageRulesActive from '../../../resources/images/manage-rules-active.svg';
import manageRulesInactive from '../../../resources/images/manage-rules-inactive.svg';

const HomePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const { email, market, category, brand } = user;

    const [programStatus, setProgramStatus] = useState('');

    const fetchLoyaltyProgram = () => {
        dispatch(showLoading());
        getLoyaltyProgram(market, category, brand)
            .then((response) => {
                dispatch(storeProgramInfo(response.data));
                if (response.data.status === C.LOYALTY_PROGRAM_STATUS_DRAFT) {
                    setProgramStatus(C.LOYALTY_PROGRAM_STATUS_DRAFT);
                } else if (
                    response.data.status === C.LOYALTY_PROGRAM_STATUS_PUBLISHED
                ) {
                    setProgramStatus(C.LOYALTY_PROGRAM_STATUS_PUBLISHED);
                } else {
                    setProgramStatus(C.LOYALTY_PROGRAM_STATUS_NA);
                }
            })
            .catch((error) => {
                if (error.statusCode === 404) {
                    setProgramStatus(C.LOYALTY_PROGRAM_STATUS_NA);
                }
            })
            .finally(() => dispatch(hideLoading()));
    };

    useEffect(() => {
        dispatch(updateCurrentComponent(C.APP_HOME_PAGE_TITLE));
        dispatch(hasSideBar(false));
        fetchLoyaltyProgram();
    }, [email, market, category, brand, programStatus]);

    const createNewProgram = (e) => {
        dispatch(showLoading());
        const createProgramBody = getCreateLoyaltyProgramPayload(
            C.LOYALTY_PROGRAM_STATUS_DRAFT
        );
        createLoyaltyProgram(market, category, brand, createProgramBody)
            .then((response) => {
                dispatch(storeProgramInfo(response.data));
                navigate('/create-program');
            })
            .catch((error) => {
                dispatch(
                    notifyError('Error!', 'Unable to create new program.')
                );
            })
            .finally(() => dispatch(hideLoading()));
    };
    const openDraftedProgram = (e) => {
        dispatch(showLoading());
        getLoyaltyProgram(market, category, brand)
            .then((response) => {
                dispatch(storeProgramInfo(response.data));
                navigate('/create-program');
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to open draft.'));
            })
            .finally(() => dispatch(hideLoading()));
    };
    const handleClick = (event) => {
        const divElement = event.currentTarget.closest('.card');

        if (divElement && divElement.classList.contains('inactive')) {
            event.preventDefault();
        }
    };
    return (
        <Box className="homepage-container">
            <Paper className="welcome-banner">
                <h1 className="banner-heading">Welcome {email.firstName}!</h1>
                <h2 className="banner-subheading">
                    Manage Your PMI Loyalty Program
                </h2>
                <p className="banner-text">
                    Create and manage loyalty rules, view reports <br /> and
                    manage loyalty customer service requests.
                </p>
            </Paper>
            <Box className="homepage-create-program-container">
                {programStatus === C.LOYALTY_PROGRAM_STATUS_NA && (
                    <>
                        <p>You do not have an active loyalty program</p>

                        <button
                            className="create-program-btn"
                            id="createNewProgram"
                            onClick={(e) => createNewProgram(e)}
                        >
                            Create New Loyalty Program
                        </button>
                    </>
                )}
                {programStatus === C.LOYALTY_PROGRAM_STATUS_DRAFT && (
                    <>
                        <p>You have saved a program in draft</p>

                        <button
                            className="create-program-btn"
                            id="openDraftedProgram"
                            onClick={(e) => openDraftedProgram(e)}
                        >
                            Open Drafted Loyalty Program
                        </button>
                    </>
                )}
            </Box>

            <Box className="homepage-cards-container">
                <div
                    className={`card ${
                        programStatus === C.LOYALTY_PROGRAM_STATUS_PUBLISHED
                            ? 'active'
                            : 'inactive'
                    }`}
                >
                    <div className="card-header">
                        <img
                            className="card-image"
                            src={
                                programStatus ===
                                C.LOYALTY_PROGRAM_STATUS_PUBLISHED
                                    ? manageRulesActive
                                    : manageRulesInactive
                            }
                            alt="Manage Rules"
                        />
                        <h2 className="card-title">Manage Loyalty Program</h2>
                    </div>
                    <hr className="gradient-border" />
                    <div className="card-body">
                        <Link to="/manage-tiers" onClick={handleClick}>
                            <div className="card-item">
                                <Typography variant="h6">Tiers</Typography>
                                <KeyboardArrowRightIcon />
                            </div>
                        </Link>
                        <Link to="/manage-events" onClick={handleClick}>
                            <div className="card-item">
                                <Typography variant="h6">
                                    Event Activities
                                </Typography>
                                <KeyboardArrowRightIcon />
                            </div>
                        </Link>
                        <Link to="/manage-rules" onClick={handleClick}>
                            <div className="card-item">
                                <Typography variant="h6">Rules</Typography>
                                <KeyboardArrowRightIcon />
                            </div>
                        </Link>
                    </div>
                </div>
                <div
                    className={`card ${
                        programStatus === C.LOYALTY_PROGRAM_STATUS_PUBLISHED
                            ? 'active'
                            : 'inactive'
                    }`}
                >
                    <div className="card-header">
                        <img
                            className="card-image"
                            src={
                                programStatus ===
                                C.LOYALTY_PROGRAM_STATUS_PUBLISHED
                                    ? customerServiceActive
                                    : customerServiceInactive
                            }
                            alt="Customer Service"
                        />
                        <h2 className="card-title">Customer Service</h2>
                    </div>
                    <hr className="gradient-border" />
                    <div className="card-body">
                        <div>
                            <Typography variant="h6">
                                View customer service requests, resolve and
                                manage them on the Consumer Admin Portal’s
                                Loyalty section
                            </Typography>
                            <a
                                className="card-external-link"
                                href={process.env.REACT_APP_CC_ADMIN_PORTAL}
                                target="_blank"
                                onClick={handleClick}
                            >
                                <img
                                    src={
                                        programStatus ===
                                        C.LOYALTY_PROGRAM_STATUS_PUBLISHED
                                            ? externalLinkActive
                                            : externalLinkInactive
                                    }
                                    alt="Customer Support"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default HomePage;
