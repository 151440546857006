import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setStep } from '../../../store/actions/programActions';
import {
    hideLoading,
    showLoading,
} from '../../../store/actions/loadingActions';
import {
    createPointSource,
    getPointSourceList,
    updatePointSource,
} from '../../../services/api/pointSourceApi';
import { notifyError } from '../../../store/actions/notificationActions';
import {
    getCreatePointSourcePayload,
    getUpdatePonintSourcePayload,
} from '../../../services/api/apiPayloads';
import { normalizeString } from '../../../services/typeUtils';
import * as C from '../../../services/constants';

import deleteIcon from '../../../resources/images/delete-icon.svg';

const pointSrcReqObj = {
    name: '',
    maxPoints: 0,
    loyaltyPointSourceId: 0,
    status: 'DISCONTINUED',
};

const DefinePointSource = () => {
    const dispatch = useDispatch();
    const { auth, program } = useSelector((state) => state);

    const { programInfo, step } = program;
    const { market, category, brand } = auth.user;

    const [nextEnabled, setNextEnabled] = useState(false);
    const [pointSrc, setPointSrc] = useState([]);

    const handleChange = (e, idx) => {
        const { type, name, value, checked } = e.target;
        const updatedSrc = pointSrc.map((src, index) => {
            if (index === idx) {
                switch (name) {
                    case 'name': {
                        if (
                            C.REGEX_POINT_SOURCE_NAME.test(
                                normalizeString(value)
                            ) ||
                            value === ''
                        ) {
                            return { ...src, name: value };
                        } else {
                            return src;
                        }
                    }
                    case 'maxPoints': {
                        if (
                            C.REGEX_POINT_SOURCE_MAXPOINTS.test(value) ||
                            value === ''
                        ) {
                            return { ...src, maxPoints: value };
                        } else {
                            return src;
                        }
                    }
                    default: {
                        if (type === 'radio') {
                            return { ...src, isDefaultSourceAccount: checked };
                        } else {
                            return src;
                        }
                    }
                }
            } else {
                return src;
            }
        });
        setPointSrc(updatedSrc);
    };

    const validateSrcAccountData = () => {
        const isInvalid = pointSrc.some((src) => {
            return Object.values(src).some(
                (value) => value === null || value === undefined || value === ''
            );
        });

        return !isInvalid;
    };

    const handleClick = (e) => {
        if (nextEnabled) {
            const pointSrcId = pointSrc[0].loyaltyPointSourceId || null;

            dispatch(showLoading());
            if (pointSrcId) {
                const updatePointSrcBody = getUpdatePonintSourcePayload(
                    pointSrc[0].name,
                    pointSrc[0].maxPoints,
                    C.POINT_SOURCE_TYPE_ACTIVE
                );
                updatePointSource(
                    market,
                    category,
                    brand,
                    pointSrcId,
                    updatePointSrcBody
                )
                    .then(() => dispatch(setStep(step + 1)))
                    .catch((error) => {
                        dispatch(notifyError(error.info));
                    })
                    .finally(() => dispatch(hideLoading()));
            } else {
                const createPointSrcBody = getCreatePointSourcePayload(
                    pointSrc[0].name,
                    pointSrc[0].maxPoints,
                    C.POINT_SOURCE_TYPE_ACTIVE
                );
                createPointSource(market, category, brand, createPointSrcBody)
                    .then(() => dispatch(setStep(step + 1)))
                    .catch((error) => dispatch(notifyError(error.info)))
                    .finally(() => dispatch(hideLoading()));
            }
        }
    };

    useEffect(() => {
        dispatch(showLoading());
        getPointSourceList(market, category, brand)
            .then((response) => {
                const pointSourceData = response?.data?.[0];
                if (pointSourceData) {
                    setPointSrc([
                        {
                            name: pointSourceData.name || '',
                            maxPoints: pointSourceData.maxPoints || 0,
                            loyaltyPointSourceId:
                                pointSourceData.loyaltyPointSourceId || 0,
                        },
                    ]);
                } else {
                    setPointSrc([{ ...pointSrcReqObj }]);
                }
            })
            .catch((error) => {
                dispatch(
                    notifyError('Error!', 'Unable to fetch point source.')
                );
            })
            .finally(dispatch(hideLoading()));
    }, []);
    useEffect(() => {
        const isValidData = validateSrcAccountData();
        setNextEnabled(isValidData);
    }, [pointSrc]);
    return (
        <div className="define-point-source-container">
            <p>
                Define 1 or more point source accounts and default any 1 for
                this loyalty program.
            </p>
            <p>
                You may add and default new source accounts later in manage
                loyalty program.
            </p>
            <div className="define-point-source">
                {pointSrc.map((pointSource, idx) => (
                    <div className="point-source" key={idx}>
                        <div className="source-account-name">
                            <label>Name Source Account: </label>
                            <input
                                type="text"
                                placeholder="e.g. Loyalty Points FY 24-25"
                                name="name"
                                onChange={(e) => handleChange(e, idx)}
                                value={pointSource?.name || ''}
                            />
                        </div>
                        <div className="allocated-points">
                            <label>Enter Allocated Points: </label>
                            <input
                                type="number"
                                placeholder="Enter value"
                                name="maxPoints"
                                onChange={(e) => handleChange(e, idx)}
                                value={pointSource?.maxPoints || 0}
                                disabled
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="define-point-cta-container">
                <button
                    className={`save-and-continue ${
                        nextEnabled ? 'active' : 'inactive'
                    }`}
                    id="saveAndContinue"
                    onClick={(e) => {
                        handleClick(e);
                    }}
                >
                    Save & Continue
                </button>
            </div>
        </div>
    );
};

export default DefinePointSource;
