// Storage utils

export function setSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
}
export function getSessionStorage(key) {
    return sessionStorage.getItem(key);
}
export function removeSessionStorage(key) {
    sessionStorage.removeItem(key);
}
export function setLocalStorage(key, value) {
    localStorage.setItem(key, value);
}
export function getLocalStorage(key) {
    return localStorage.getItem(key);
}
export function removeLocalStorage(key) {
    localStorage.removeItem(key);
}
