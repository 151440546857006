import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SideNav from '../../common/SideNav';
import {
    hasSideBar,
    toggleSideBar,
    updateCurrentComponent,
} from '../../../store/actions/navActions';
import { faqList } from './FaqList';
import * as C from '../../../services/constants';

import chevronDownIcon from '../../../resources/images/chevron-down.svg';
import chevronUpIcon from '../../../resources/images/chevron-up.svg';

const Help = () => {
    const dispatch = useDispatch();

    const [expandedItems, setExpandedItems] = useState([]);
    const [allExpanded, setAllExpanded] = useState(false);

    useEffect(() => {
        dispatch(updateCurrentComponent(C.APP_HELP_PAGE_TITLE));
        dispatch(hasSideBar(true));
        dispatch(toggleSideBar(false));
    });

    const handleToggleAccordion = (index) => {
        let newExpandedItems = [...expandedItems];
        const currentIndex = newExpandedItems.indexOf(index);

        if (currentIndex === -1) {
            newExpandedItems.push(index);
        } else {
            newExpandedItems.splice(currentIndex, 1);
        }

        setExpandedItems(newExpandedItems);
        setAllExpanded(newExpandedItems.length === faqList.length);
    };

    const handleCollapseAll = () => {
        setExpandedItems([]);
        setAllExpanded(false);
    };

    const handleExpandAll = () => {
        const allIndexes = faqList.map((_, index) => index);
        setExpandedItems(allIndexes);
        setAllExpanded(true);
    };

    return (
        <div className="component-container-root">
            <SideNav />
            <div className="help-container">
                <h2>How to Manage the Loyalty Program</h2>
                <h3>Tiers: </h3>
                <ul>
                    <li>
                        You can add new tiers, but you cannot edit/delete the
                        existing tiers.
                    </li>
                </ul>
                <h3>Events:</h3>
                <ul>
                    <li>
                        Please Note: *You can add new event activities, but you
                        cannot edit/delete the existing entries.
                    </li>
                </ul>
                <h3>Rules:</h3>
                <ul>
                    <li>
                        You can expire but not edit the existing activity rules.
                    </li>
                    <li>You can not expire or edit the existing tier rules.</li>
                    <li>You add new rules for tiers and event activities.</li>
                </ul>

                <div className="faq-header">
                    <h2>Frequently Asked Questions</h2>
                    <button
                        className="faq-collapse-all"
                        onClick={
                            allExpanded ? handleCollapseAll : handleExpandAll
                        }
                    >
                        <img
                            src={allExpanded ? chevronUpIcon : chevronDownIcon}
                            alt="collapse all"
                        />{' '}
                        {allExpanded ? 'Collapse All' : 'Expand All'}
                    </button>
                </div>
                <div style={{ width: '70%' }}>
                    {faqList.map((faq, index) => (
                        <Accordion
                            key={index}
                            expanded={expandedItems.includes(index)}
                            onChange={() => handleToggleAccordion(index)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h3>{faq.question}</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul>
                                    {faq.answer.map((ans, idx) => (
                                        <li
                                            key={idx}
                                            dangerouslySetInnerHTML={{
                                                __html: ans,
                                            }}
                                        />
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Help;
