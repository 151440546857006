import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import { toggleSideBar } from '../../store/actions/navActions';
import * as C from '../../services/constants';

import HomeIcon from '../../resources/images/home-icon.svg';
import ManageProgramIcon from '../../resources/images/manage-program.svg';
import CustomerServiceIcon from '../../resources/images/customer-support.svg';
import extLinkIcon from '../../resources/images/external-link.svg';

const SideNav = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { navigation, program } = useSelector((state) => state);

    const open = navigation.openSideBar;
    const isPublished =
        program.programInfo.status === C.LOYALTY_PROGRAM_STATUS_PUBLISHED;
    const openAdminPortal = () =>
        window.open(process.env.REACT_APP_CC_ADMIN_PORTAL, '_blank');

    return (
        <div
            className={`sidenav ${open ? 'open' : 'closed'}`}
            style={{
                background:
                    'linear-gradient(to bottom, #003DA5 2.6%, #46DB8C 100%)',
            }}
        >
            <List>
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            onClick={() => navigate('/')}
                        >
                            <img src={HomeIcon} alt="home" />
                        </ListItemIcon>
                        <Link to="/">
                            <ListItemText
                                primary="Home"
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            onClick={() => dispatch(toggleSideBar(true))}
                        >
                            <img src={ManageProgramIcon} alt="manage program" />
                        </ListItemIcon>
                        <ListItemText
                            primary="Manage Loyalty Program"
                            sx={{ opacity: open ? 1 : 0 }}
                        />
                    </ListItemButton>
                    <Collapse in={open}>
                        <List className="sub-menu">
                            <ListItem>
                                <Link to={isPublished ? '/manage-tiers' : '#'}>
                                    <ListItemText primary="Tiers" />
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link to={isPublished ? '/manage-events' : '#'}>
                                    <ListItemText primary="Event Activities" />
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link to={isPublished ? '/manage-rules' : '#'}>
                                    <ListItemText primary="Rules" />
                                </Link>
                            </ListItem>
                        </List>
                    </Collapse>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        onClick={openAdminPortal}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={CustomerServiceIcon}
                                alt="customer service"
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary="Customer Service"
                            sx={{ opacity: open ? 1 : 0 }}
                        />
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            className="nav-external-link-icon"
                        >
                            <img src={extLinkIcon} alt="customer service" />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );
};

export default SideNav;
