// Action types

export const AUTH_SET_USER = 'AUTH_SET_USER';
export const LOCALE_SET_LANG = 'LOCALE_SET_LANG';

export const PROGRAM_STORE_INFO = 'PROGRAM_STORE_INFO';

export const PROGRAM_SET_STEP = 'PROGRAM_SET_STEP';
export const PROGRAM_ASSIGN_TIERS = 'PROGRAM_ASSIGN_TIERS';

export const NAVIGATION_HAS_SIDEBAR = 'NAVIGATION_HAS_SIDEBAR';
export const NAVIGATION_TOGGLE_SIDEBAR = 'NAVIGATION_TOGGLE_SIDEBAR';
export const NAVIGATION_UPDATE_COMP_NAME = 'NAVIGATION_UPDATE_COMP_NAME';

export const NOTIFICATION_OPEN = 'NOTIFICATION_OPEN';
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE';

export const LOADING_SHOW = 'LOADING_SHOW';
export const LOADING_HIDE = 'LOADING_HIDE';
