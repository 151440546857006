import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { Provider as ReduxProvider } from 'react-redux';

import App from './App';
import config from './config';
import configureStore from './store/configureStore';
import './index.css';
import './styles/sass/index.scss';

Amplify.configure(config);
const store = configureStore();

ReactDOM.render(
    <ReduxProvider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ReduxProvider>,
    document.getElementById('root')
);
