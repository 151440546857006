import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import RuleEditor from '../../common/RuleEditor';
import ConfirmDialog from '../../common/ConfirmDialog';
import { setStep } from '../../../store/actions/programActions';
import { updateLoyaltyProgram } from '../../../services/api/programApi';
import { getUpdateLoyaltyProgramPayload } from '../../../services/api/apiPayloads';
import { hideLoading, showLoading } from '../../../store/actions/loadingActions';
import { extractEvent, extractEventAttribute } from '../../../services/ruleUtils';
import { getRuleList } from '../../../services/api/ruleApi';
import { notify, notifyError, notifySuccess } from '../../../store/actions/notificationActions';
import * as C from '../../../services/constants';

import editBtn from '../../../resources/images/edit.svg';

const rulesTableHeaders = {
    operation: 'Action',
    ruleAppliesTo: 'Rule Applies To',
    eventAttribute: 'Event Attribute',
    description: 'Description',
};

const DefineRules = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fields = Object.keys(rulesTableHeaders);
    const { auth, program } = useSelector((state) => state);

    const { market, category, brand } = auth.user;
    const { programInfo, step } = program;

    const [showRuleEditor, setShowRuleEditor] = useState(false);
    const [rules, setRules] = useState([]);
    const [ruleId, setRuleId] = useState(null);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [page, setPage] = useState(0);

    const handlePageChange = (event, page) => {
        setPage(page);
    };
    const goLive = (e) => {
        const { id } = e.target;
        const updateProgramBody = getUpdateLoyaltyProgramPayload(
            C.LOYALTY_PROGRAM_STATUS_PUBLISHED
        );
        dispatch(showLoading());
        updateLoyaltyProgram(
            market,
            category,
            brand,
            programInfo.loyaltyProgramId,
            updateProgramBody
        )
            .then(() => {
                dispatch(setStep(step + 1));
                dispatch(
                    notifySuccess(
                        'Success!',
                        'Your loyalty program has been successfully published.'
                    )
                );
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to Publish Program'));
            })
            .finally(() => dispatch(hideLoading()));
    };
    const draftProgram = () => {
        dispatch(showLoading(true));
        const updateProgramBody = getUpdateLoyaltyProgramPayload(
            C.LOYALTY_PROGRAM_STATUS_DRAFT
        );
        updateLoyaltyProgram(
            market,
            category,
            brand,
            programInfo.loyaltyProgramId,
            updateProgramBody
        )
            .then(() => {
                dispatch(notify('Saved!', 'Your changes are saved in draft.'));
                dispatch(setStep(0));
                navigate('/');
            })
            .catch((error) => {
                dispatch(
                    notifyError('Error!', 'Unable to save program in draft.')
                );
            })
            .finally(() => dispatch(hideLoading()));
    };
    const editRule = (e, loyaltyRuleId = null) => {
        setRuleId(loyaltyRuleId);
        setShowRuleEditor(true);
    };
    const addRule = () => {
        setShowRuleEditor(true);
    };
    const closeRuleEditor = () => {
        setRuleId(null);
        setShowRuleEditor(false);
    };
    useEffect(() => {
        dispatch(showLoading());
        getRuleList(market, category, brand)
            .then((response) => {
                const existingRules = response.data.map((rule) => ({
                    loyaltyRuleId: rule.loyaltyRuleId,
                    operation: C.RULE_PROPERTY_OPERATION_EDIT,
                    ruleAppliesTo: extractEvent(rule.condition),
                    eventAttribute: extractEventAttribute(rule.condition),
                    description: rule.description,
                }));
                setRules(existingRules);
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to fetch rules.'))
            })
            .finally(() => dispatch(hideLoading()));
    }, [showRuleEditor]);
    useEffect(() => {
        setNextEnabled(rules.length > 0);
    }, [rules]);
    return (
        <>
            {showRuleEditor ? (
                <RuleEditor
                    onClose={() => closeRuleEditor()}
                    saveRule={setRules}
                    ruleId={ruleId}
                    origin={C.RULE_EDITOR_CREATE_PROGRAM}
                />
            ) : (
                <div className="define-rules-container">
                    <p>
                        Define rules and conditions to be fulfilled in your
                        loyalty program:
                    </p>
                    <p>
                        <span>Please Note:</span> *You must define at least 1
                        rule for an event activity for the users to earn points
                    </p>

                    <div className="define-rules">
                        <TableContainer className="table-container">
                            <Table className="table">
                                <TableHead className="table-head">
                                    <TableRow className="table-row">
                                        {fields.map((field, idx) => (
                                            <TableCell
                                                key={idx}
                                                className="table-cell"
                                            >
                                                {rulesTableHeaders[field]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table-body">
                                    {rules
                                        .slice(page * 10, page * 10 + 10)
                                        .map((rule, idx) => (
                                            <TableRow
                                                key={idx}
                                                className="table-row"
                                            >
                                                {fields.map((field, idx) => (
                                                    <TableCell
                                                        key={idx}
                                                        className="table-cell"
                                                    >
                                                        {field ===
                                                        C.RULE_PROPERTY_OPERATION ? (
                                                            <img
                                                                src={editBtn}
                                                                alt="Edit Rule"
                                                                onClick={(e) =>
                                                                    editRule(
                                                                        e,
                                                                        rule.loyaltyRuleId
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            rule[field]
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <div className="table-footer">
                                <button
                                    className="add-rule"
                                    onClick={() => addRule()}
                                >
                                    + Add Rule
                                </button>
                                <TablePagination
                                    component="div"
                                    count={rules.length}
                                    rowsPerPage={10}
                                    rowsPerPageOptions={[]}
                                    page={page}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </TableContainer>
                    </div>
                    <div className="define-rules-cta-container">
                        <button
                            className={`go-live ${
                                nextEnabled ? 'active' : 'inactive'
                            }`}
                            id="goLive"
                            onClick={(e) =>
                                nextEnabled && setShowConfirmDialog(true)
                            }
                        >
                            Go Live
                        </button>
                        <button
                            className={`save-and-continue ${
                                nextEnabled ? 'active' : 'inactive'
                            }`}
                            id="draftProgram"
                            onClick={(e) => nextEnabled && draftProgram(e)}
                        >
                            Save as Draft
                        </button>
                    </div>
                    {showConfirmDialog && (
                        <ConfirmDialog
                            title="Confirm: Publish and Go Live"
                            message="Are you sure you want to publish and take the program live?"
                            note="Once you go live you cannot edit the tiers and rules defined here. You can however:
                        Add new tiers and rules. 
                        Expire rules "
                            confirmText="Yes, Publish the Program"
                            backText="No, Continue Editing"
                            onClose={() => setShowConfirmDialog(false)}
                            onConfirm={goLive}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default DefineRules;
