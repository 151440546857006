import React from 'react';
import closeIcon from '../../resources/images/close.svg'

const ConfirmDialog = ({ title, message, note, onClose, onConfirm, confirmText, backText }) => {
  return (
      <div className="confirm-dialog-container">
          <div className="confirm-dialog">
              <div className="confirm-dialog-header">
                  <h3>{title}</h3>
                  <img
                        src={closeIcon}
                        alt="close dialog"
                        className="close-tier-dialog"
                        onClick={onClose}
                    />
              </div>
              <div className="confirm-dialog-content">
                <div className="confirm-dialog-message">{message}</div>
                {note && <div className="confirm-dialog-note">
                    <p>Please Note:</p>
                    <p>{note}</p>
                </div>}
              </div>
              <div className="confirm-dialog-cta-container">
                  <button onClick={onConfirm}>{confirmText}</button>
                  <button onClick={onClose}>{backText}</button>
              </div>
          </div>
      </div>
  );
};

export default ConfirmDialog;
