import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    isLoading: false,
};

const loadingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOADING_SHOW:
            return { ...state, isLoading: true };

        case types.LOADING_HIDE:
            return { ...state, isLoading: false };

        default:
            return state;
    }
};

export default loadingReducer;
