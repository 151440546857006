import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDialog from '../../common/ConfirmDialog';
import { hideLoading, showLoading } from '../../../store/actions/loadingActions';
import { notify, notifyError, notifySuccess } from '../../../store/actions/notificationActions';
import { createTier, deleteTier, updateTier } from '../../../services/api/tierApi';
import { getTierPayload } from '../../../services/api/apiPayloads';
import { hasDuplicateTiers } from '../../../services/tierUtils';
import { normalizeString } from '../../../services/typeUtils';
import * as C from '../../../services/constants';

import closeIcon from '../../../resources/images/close.svg';

const TierDetails = ({ status, type, tier, tiers, onClose, highestRank }) => {
    const dispatch = useDispatch();
    const { market, category, brand } = useSelector((state) => state.auth.user);

    const [tierInfo, setTierInfo] = useState({ ...tier });
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [ctaEnabled, setCtaEnabled] = useState(false);

    const openConfirmDialog = () => {
        setShowConfirmDialog(true);
    };
    const draftTier = () => {
        const { loyaltyTierId, name, description, points, ranking } = tierInfo;
        if (hasDuplicateTiers(tiers, tierInfo)) {
            dispatch(notifyError('Error!', 'Duplicate values found.'));
        } else {
            if (type === C.TIER_TYPE_NEW) {
                const newTierPayload = getTierPayload(
                    name,
                    description,
                    points,
                    highestRank + 1,
                    C.TIER_TYPE_DRAFT
                );
                dispatch(showLoading());
                createTier(market, category, brand, newTierPayload)
                    .then((response) => {
                        dispatch(
                            notify(
                                'Saved!',
                                'Your work has been saved in drafts.'
                            )
                        );
                    })
                    .catch((error) => {
                        dispatch(
                            notifyError('Error!', 'Unable to save draft.')
                        );
                    })
                    .finally(() => {
                        dispatch(hideLoading());
                        onClose();
                    });
            } else {
                const updateTierPayload = getTierPayload(
                    name,
                    description,
                    points,
                    ranking,
                    C.TIER_TYPE_DRAFT
                );
                dispatch(showLoading());
                updateTier(
                    market,
                    category,
                    brand,
                    loyaltyTierId,
                    updateTierPayload
                )
                    .then((response) => {
                        dispatch(
                            notify(
                                'Saved!',
                                'Your work has been saved in drafts.'
                            )
                        );
                    })
                    .catch((error) => {
                        dispatch(notifyError('Error!', 'Unable to save draft.'));
                    })
                    .finally(() => {
                        dispatch(hideLoading());
                        onClose();
                    });
            }
        }
    };
    const publishTier = () => {
        const { loyaltyTierId, name, description, points, ranking } = tierInfo;
        if (hasDuplicateTiers(tiers, tierInfo)) {
            dispatch(notifyError('Error!', 'Duplicate values found.'));
        } else {
            if (type === C.TIER_TYPE_NEW) {
                const newTierPayload = getTierPayload(
                    name,
                    description,
                    points,
                    highestRank + 1,
                    C.TIER_TYPE_PUBLISHED
                );
                dispatch(showLoading());
                createTier(market, category, brand, newTierPayload)
                    .then((response) => {
                        dispatch(
                            notifySuccess(
                                'Published!',
                                'The tier has been successfully published.'
                            )
                        );
                    })
                    .catch((error) => {
                        dispatch(notifyError('Error!', 'Unable to publish tier.'));
                    })
                    .finally(() => {
                        dispatch(hideLoading());
                        onClose();
                    });
            } else {
                const updateTierPayload = getTierPayload(
                    name,
                    description,
                    points,
                    ranking,
                    C.TIER_TYPE_PUBLISHED
                );
                dispatch(showLoading());
                updateTier(
                    market,
                    category,
                    brand,
                    loyaltyTierId,
                    updateTierPayload
                )
                    .then((response) => {
                        dispatch(
                            notifySuccess(
                                'Published!',
                                'The tier has been successfully published.'
                            )
                        );
                    })
                    .catch((error) => {
                        dispatch(notifyError('Error!', 'Unable to publish tier.'));
                    })
                    .finally(() => {
                        dispatch(hideLoading());
                        onClose();
                    });
            }
        }
    };

    const removeTier = () => {
        const { loyaltyTierId } = tierInfo;
        dispatch(showLoading());
        deleteTier(market, category, brand, loyaltyTierId)
            .then((response) => {
                dispatch(notifySuccess('Deleted!', 'Draft deleted successully.'));
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to delete draft.'));
            })
            .finally(() => {
                dispatch(hideLoading());
                onClose();
            });
    };
    const validateTierData = () => {
        const requiredFields = ['name', 'points', 'description'];

        return requiredFields.every((field) => {
            return (
                tierInfo[field] !== null &&
                tierInfo[field] !== undefined &&
                tierInfo[field] !== ''
            );
        });
    };
    const handleChange = (e) => {
        const { value, name } = e.target;
        switch (name) {
            case 'name': {
                if (C.REGEX_TIER_NAME.test(normalizeString(value)) || value === '') {
                    setTierInfo((tier) => ({ ...tier, name: value }));
                } else {
                    setTierInfo((tier) => ({ ...tier, name: '' }));
                }
                break;
            }
            case 'points': {
                if (C.REGEX_TIER_POINTS.test(value) || value === '') {
                    setTierInfo((tier) => ({ ...tier, points: value }));
                } else {
                    setTierInfo((tier) => ({ ...tier, points: '' }));
                }
                break;
            }
            case 'description': {
                if (C.REGEX_TIER_DESCRIPTION.test(normalizeString(value)) || value === '') {
                    setTierInfo((tier) => ({ ...tier, description: value }));
                } else {
                    setTierInfo((tier) => ({ ...tier, description: '' }));
                }
                break;
            }
            default: {
                break;
            }
        }

        // const updatedValue = value !== undefined ? value : '';
        // setTierInfo({ ...tierInfo, [name]: updatedValue });
    };

    useEffect(() => {
        const isValidData = validateTierData();
        setCtaEnabled(isValidData);
    }, [tierInfo]);
    return (
        <div className="tier-details-dialog">
            {showConfirmDialog ? (
                <ConfirmDialog
                    title="Confirm: Publish Tiers!"
                    message="Are you sure you want to publish this new tier to your loyalty program?"
                    note="Once you publish here you can not edit or update this tier. "
                    confirmText="Yes, Publish Tier"
                    backText="No, Continue Editing"
                    onClose={() => setShowConfirmDialog(false)}
                    onConfirm={publishTier}
                />
            ) : (
                <div className="tier-details">
                    <div className="tier-header">
                        <h2 className="tier-title">
                            {type === C.TIER_TYPE_NEW
                                ? 'Add New Tier'
                                : 'View Tier'}
                        </h2>
                        <img
                            src={closeIcon}
                            alt="close dialog"
                            className="close-tier-dialog"
                            onClick={onClose}
                        />
                    </div>
                    <div className="tier-details-form">
                        <div className="tier-info">
                            <div className="tier-name">
                                <label>Tier Name: </label>
                                <input
                                    type="text"
                                    placeholder="e.g. Bronze"
                                    name="name"
                                    value={tierInfo.name}
                                    onChange={(e) => handleChange(e)}
                                    disabled={type === C.TIER_TYPE_PUBLISHED}
                                />
                            </div>
                            <div className="tier-points">
                                <label>Required Points to Enter Tier: </label>
                                <input
                                    type="text"
                                    placeholder="Enter no. of points"
                                    name="points"
                                    value={tierInfo.points}
                                    onChange={(e) => handleChange(e)}
                                    disabled={type === C.TIER_TYPE_PUBLISHED}
                                />
                            </div>
                        </div>
                        <div className="tier-description">
                            <label>Tier Description: </label>
                            <input
                                type="text"
                                placeholder="e.g. Basic entry level tier"
                                name="description"
                                value={tierInfo.description}
                                onChange={(e) => handleChange(e)}
                                disabled={type === C.TIER_TYPE_PUBLISHED}
                            />
                        </div>
                    </div>

                    {type !== C.TIER_TYPE_PUBLISHED && (
                        <div className="tier-detials-cta-container">
                            <button
                                className={`${ctaEnabled ? '' : 'disabled'}`}
                                onClick={() =>
                                    ctaEnabled && openConfirmDialog()
                                }
                            >
                                Publish Tier
                            </button>
                            <button
                                className={`${ctaEnabled ? '' : 'disabled'}`}
                                onClick={() => ctaEnabled && draftTier()}
                            >
                                Save as Draft
                            </button>
                            <button
                                className={`${
                                    tierInfo?.loyaltyTierId && ctaEnabled
                                        ? ''
                                        : 'disabled'
                                }`}
                                onClick={() =>
                                    tierInfo?.loyaltyTierId &&
                                    ctaEnabled &&
                                    removeTier()
                                }
                            >
                                Delete
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default TierDetails;
