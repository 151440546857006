export const theme = {
    palette: {
        background: {
            // default: '#F4FCFF',
            appHeader: '#FFFFFF',
            footerBg: '#F6F6F6',
        },
        componentBg: {
            main: '#FFFFFF',
        },
        primary: {
            main: '#1D5B9F',
        },
        disabled: {
            main: '#C7C4C5',
        },
        success: {
            main: '#46DB8C',
        },
        error: {
            main: '#C8102E',
        },
        text: {
            primary: '#000000',
            secondary: '#ffffff',
        },
    },
    typography: {
        fontFamily: [
            '"Lato"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
};
