// Loading indicator actions

import * as types from './actionTypes';

export const showLoading = () => ({
    type: types.LOADING_SHOW,
  });
  
  export const hideLoading = () => ({
    type: types.LOADING_HIDE,
  });
  