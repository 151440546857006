import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';

import SideNav from '../../common/SideNav';
import EventDetails from './EventDetails';
import {
    hasSideBar,
    toggleSideBar,
    updateCurrentComponent,
} from '../../../store/actions/navActions';
import {
    hideLoading,
    showLoading,
} from '../../../store/actions/loadingActions';
import { notifyError } from '../../../store/actions/notificationActions';
import { getEventList } from '../../../services/api/ruleApi';
import { formatDate } from '../../../services/formatUtils';
import { Email } from '../../../services/Email';
import * as C from '../../../services/constants';

const manageEventsTableHeader = {
    status: 'Status',
    name: 'Event Activity',
    description: 'Event Activity Description',
    updatedTs: 'Published Date',
    updatedBy: 'Publisher EID',
};

const ManageEvents = () => {
    const dispatch = useDispatch();
    const fields = Object.keys(manageEventsTableHeader);

    const { auth } = useSelector((state) => state);
    const { market, category, brand } = auth.user;

    const [events, setEvents] = useState([]);
    const [showEvent, setShowEvent] = useState(false);
    const [type, setType] = useState(C.EVENT_TYPE_NEW);
    const [page, setPage] = useState(0);
    const [event, setEvent] = useState({});

    const handlePageChange = (event, page) => {
        setPage(page);
    };
    useEffect(() => {
        dispatch(updateCurrentComponent(C.APP_MANAGE_EVENTS_PAGE_TITLE));
        dispatch(hasSideBar(true));
        dispatch(toggleSideBar(false));

        dispatch(showLoading());
        getEventList(market, category, brand)
            .then((response) => {
                const existingEvents = response.data.map((event) => ({
                    loyaltyEventTypeId: event.loyaltyEventTypeId,
                    name: event.name,
                    description: event.description,
                    status: event.status,
                    updatedTs: formatDate(event.updatedTs),
                    updatedBy: new Email(event.updatedBy).user,
                }));
                setEvents(existingEvents);
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to fetch events.'));
            })
            .finally(() => dispatch(hideLoading()));
    }, []);
    const openEvent = (status, eventId = null) => {
        if (status === C.EVENT_TYPE_PUBLISHED) {
            setType(C.EVENT_TYPE_PUBLISHED);
            setEvent(
                events.find((event) => event.loyaltyEventTypeId === eventId)
            );
            setShowEvent(true);
        } else if (status === C.EVENT_TYPE_DRAFT) {
            setType(C.EVENT_TYPE_DRAFT);
            setEvent(
                events.find((event) => event.loyaltyEventTypeId === eventId)
            );
            setShowEvent(true);
        } else if (status === C.EVENT_TYPE_NEW) {
            setType(C.EVENT_TYPE_NEW);
            setEvent(null);
            setShowEvent(true);
        } else {
            setEvent(null);
            setShowEvent(false);
        }
    };
    const closeEvent = () => {
        setShowEvent(false);
    };
    return (
        <div className="component-container-root">
            <SideNav />
            <Box className="manage-events-container component-container">
                <p className="instruction">
                    View and manage the events of the loyalty program. Go to '
                    <Link to="/help">Help page</Link>' for information on how to
                    manage.
                </p>
                <div className="manage-events">
                    <TableContainer className="table-container">
                        <Table className="table">
                            <TableHead className="table-head">
                                <TableRow className="table-row">
                                    {fields.map((field, idx) => (
                                        <TableCell
                                            key={idx}
                                            className="table-cell"
                                        >
                                            {manageEventsTableHeader[field]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                                {events
                                    .slice(page * 10, page * 10 + 10)
                                    .map((event, rowIndex) => (
                                        <TableRow
                                            key={rowIndex}
                                            className="table-row"
                                        >
                                            {fields.map((field, idx) => (
                                                <TableCell
                                                    key={idx}
                                                    className="table-cell"
                                                >
                                                    {field ===
                                                    C.EVENT_PROPERTY_STATUS ? (
                                                        event[field] ===
                                                        C.EVENT_TYPE_PUBLISHED ? (
                                                            <span
                                                                className="view-event published"
                                                                onClick={() =>
                                                                    openEvent(
                                                                        C.EVENT_TYPE_PUBLISHED,
                                                                        event.loyaltyEventTypeId
                                                                    )
                                                                }
                                                            >
                                                                Published
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="view-event draft"
                                                                onClick={() =>
                                                                    openEvent(
                                                                        C.EVENT_TYPE_DRAFT,
                                                                        event.loyaltyEventTypeId
                                                                    )
                                                                }
                                                            >
                                                                Draft
                                                            </span>
                                                        )
                                                    ) : (
                                                        event[field]
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <div className="table-footer">
                            <div></div>
                            <TablePagination
                                component="div"
                                count={events.length}
                                rowsPerPage={10}
                                rowsPerPageOptions={[]}
                                page={page}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </TableContainer>
                </div>
            </Box>
            {showEvent && (
                <EventDetails
                    status={showEvent}
                    type={type}
                    onClose={closeEvent}
                    event={event}
                />
            )}
        </div>
    );
};

export default ManageEvents;
