import React from 'react';

import alertIcon from '../../../resources/images/alert.svg';
import closeIcon from '../../../resources/images/close.svg';

const WizardInstructions = ({ visible, initiated, setInitiated }) => {
    const handleClose = () => {
        visible(false);
    };
    const handleInitiate = () => {
        setInitiated(true);
        visible(false);
    };
    return (
        <div className="wizard-instructions">
            <div className="wizard-instruction-head">
                <div className="wizard-instruction-title">
                    {initiated && <img src={alertIcon} alt="alert icon" />}
                    <h3>How to Set Loyalty Program Rules:</h3>
                </div>

                {initiated && (
                    <img src={closeIcon} onClick={() => handleClose()} />
                )}
            </div>
            <div className="wizard-instruction-body">
                <ol>
                    <li>
                        <h5>
                            <span>Define Source Account: </span>
                            <br />
                            Name and define a source account from where the
                            points will be utilised for your loyalty program.
                            You can define more than 1 source accounts but only
                            one account at a time can be defaulted to credit the
                            points earned by the users. You can switch or add
                            new source accounts later in 'Manage Program'.
                        </h5>
                    </li>
                    <li>
                        <h5>
                            <span>Define Tiers:</span> <br /> Define a set of
                            tiers that the customers can be grouped into basis
                            their level of engagement with your brand
                            (optional).
                        </h5>
                        <p>
                            Recommended: Unlike traditional earn & burn loyalty
                            programs, tiered loyalty programs rank members into
                            certain groups based on certain metrics. It enables
                            your customers to enjoy different loyalty program
                            benefits depending on their tier/rank. This approach
                            enhances the significance of engagement activities
                            and adds a touch of gamification to the program.
                        </p>
                    </li>
                    <li>
                        <h5>
                            <span>Set Loyalty Rules:</span> <br />
                            Mention rules for the consumers to earn points or
                            level up to the tiers based on their engagement
                            activities.
                        </h5>
                    </li>

                    <li>
                        <h5>
                            <span>Go Live! </span>
                            <br />
                            Publish your loyalty program to activate it and go
                            live!
                        </h5>
                    </li>
                </ol>
                {!initiated && (
                    <button
                        className="btn-start-now"
                        onClick={() => handleInitiate()}
                    >
                        Start Now
                    </button>
                )}
            </div>
        </div>
    );
};

export default WizardInstructions;
