// AWS config

const env = process.env;

const config = {
    Auth: {
        mandatorySignId: true,
        region: env.REACT_APP_REGION,
        userPoolId: env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: env.REACT_APP_WEB_CLIENT_ID,
    },
    oauth: {
        domain: env.REACT_APP_COGNITO_DOMAIN,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: env.REACT_APP_REDIRECT_SIGN_IN,
        redirectSignOut: env.REACT_APP_REDIRECT_SIGN_OUT,
        responseType: 'code',
    },
    API: {
        endpoints: [
            {
                name: 'loyalty-admin-portal-api',
                endpoint: env.REACT_APP_API_URL,
            },
        ],
    },
};

export default config;
