export const eventFields = {
    CONSUMER: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['register', 'login'],
        },
    ],
    GAME: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['id'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    VIDEO: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['id'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    ARTICLE: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['id'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    QUIZ: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['id'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    RAFFLE: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['id'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
};
export const conditionFields = {
    CONSUMER: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['eventFrequency', 'eventCount', 'points'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['==', '>', '<', '>=', '<='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    GAME: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['title'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    VIDEO: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['title'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    ARTICLE: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['title'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    QUIZ: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['title'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
    RAFFLE: [
        {
            label: 'Event Attribute Is:',
            name: 'attributeName',
            type: 'dropdown',
            values: ['title'],
        },
        {
            label: 'Operation:',
            name: 'operation',
            type: 'dropdown',
            values: ['=='],
        },
        {
            label: 'Attribute Value Is:',
            name: 'attributeValue',
            type: 'text',
        },
    ],
};
