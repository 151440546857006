import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    Menu,
    MenuItem,
    Checkbox,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FilterListIcon from '@mui/icons-material/FilterList';

import SideNav from '../../common/SideNav';
import RuleEditor from '../../common/RuleEditor';
import {
    hasSideBar,
    toggleSideBar,
    updateCurrentComponent,
} from '../../../store/actions/navActions';
import {
    hideLoading,
    showLoading,
} from '../../../store/actions/loadingActions';
import { notifyError } from '../../../store/actions/notificationActions';
import { getRuleList } from '../../../services/api/ruleApi';
import {
    extractEvent,
    extractEventAttribute,
} from '../../../services/ruleUtils';
import {
    capitalizeFirstLetter,
    nonEmptyString,
    toUpperCase,
} from '../../../services/typeUtils';
import { formatDate } from '../../../services/formatUtils';
import { Email } from '../../../services/Email';
import * as C from '../../../services/constants';

const manageRulesTableHeader = {
    status: 'Status',
    ruleAppliesTo: 'Rule Applies To',
    eventAttribute: 'Event Attribute',
    description: 'Rule Description',
    publishedDate: 'Published Date',
    publisherEID: 'Publisher EID',
};

const statusOptions = ['published', 'draft', 'expired'];
const ruleAppliesToOptions = [
    'consumer',
    'article',
    'game',
    'video',
    'quiz',
    'raffle',
    'user tier',
];

const ManageRules = () => {
    const dispatch = useDispatch();
    const fields = Object.keys(manageRulesTableHeader);
    const { market, category, brand } = useSelector((state) => state.auth.user);

    const [rules, setRules] = useState([]);
    const [ruleId, setRuleId] = useState(null);
    const [showRuleEditor, setShowRuleEditor] = useState(false);
    const [type, setType] = useState(C.RULE_TYPE_NEW);
    const [page, setPage] = useState(0);
    const [sortBy, setSortBy] = useState('status');
    const [sortOrder, setSortOrder] = useState('asc');
    const [filterStatus, setFilterStatus] = useState([]);
    const [filterRuleAppliesTo, setFilterRuleAppliesTo] = useState([]);
    const [anchorElStatus, setAnchorElStatus] = useState(null);
    const [anchorElRuleAppliesTo, setAnchorElRuleAppliesTo] = useState(null);

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const openRuleEditor = (status, ruleId = null) => {
        setRuleId(ruleId);
        setType(status);
        setShowRuleEditor(true);
    };

    const closeRuleEditor = () => {
        setRuleId(null);
        setShowRuleEditor(false);
    };

    useEffect(() => {
        dispatch(updateCurrentComponent(C.APP_MANAGE_RULES_PAGE_TITLE));
        dispatch(hasSideBar(true));
        dispatch(toggleSideBar(false));

        dispatch(showLoading());
        getRuleList(market, category, brand)
            .then((response) => {
                const existingRules = response.data.map((rule) => ({
                    loyaltyRuleId: rule.loyaltyRuleId,
                    status: rule.status,
                    ruleAppliesTo:
                        rule.ruleType === C.RULE_FOR_POINT
                            ? extractEvent(rule.condition)
                            : C.RULE_PROPERTY_USER_TIER,
                    ruleType: rule.ruleType,
                    eventAttribute:
                        rule.ruleType === C.RULE_FOR_POINT
                            ? extractEventAttribute(rule.condition)
                            : '-',
                    description: rule.description,
                    publishedDate: formatDate(rule.updatedTs),
                    publisherEID: new Email(rule.updatedBy).user,
                }));
                setRules(existingRules);
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to fetch rules.'));
            })
            .finally(() => dispatch(hideLoading()));
    }, [showRuleEditor]);

    const handleSort = (field) => {
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortOrder('asc');
        }
    };

    const handleFilterStatus = (event) => {
        const { checked, value } = event.target;
        setFilterStatus((prevState) => {
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else {
                return prevState.filter((item) => item !== value);
            }
        });
    };

    const handleFilterRuleAppliesTo = (event) => {
        const { checked, value } = event.target;
        setFilterRuleAppliesTo((prevState) => {
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else {
                return prevState.filter((item) => item !== value);
            }
        });
    };

    const filteredAndSortedRules = rules
        .filter((rule) => {
            return (
                filterStatus.length === 0 ||
                filterStatus.includes(rule.status.toLowerCase())
            );
        })
        .filter((rule) => {
            return (
                filterRuleAppliesTo.length === 0 ||
                filterRuleAppliesTo.includes(rule.ruleAppliesTo.toLowerCase())
            );
        })
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy].toLowerCase() > b[sortBy].toLowerCase()
                    ? 1
                    : -1;
            } else {
                return a[sortBy].toLowerCase() < b[sortBy].toLowerCase()
                    ? 1
                    : -1;
            }
        });

    return (
        <div className="component-container-root">
            <SideNav />
            {showRuleEditor ? (
                <RuleEditor
                    status={showRuleEditor}
                    type={type}
                    ruleId={ruleId}
                    onClose={closeRuleEditor}
                    origin={C.RULE_EDITOR_MANAGE_PROGRAM}
                />
            ) : (
                <Box className="manage-rules-container component-container">
                    <p className="instruction">
                        View and manage the rules of the loyalty program. Go to
                        '<Link to="/help">Help page</Link>' for information on
                        how to manage.
                    </p>
                    <div className="manage-rules">
                        <TableContainer className="table-container">
                            <Table className="table">
                                <TableHead className="table-head">
                                    <TableRow className="table-row">
                                        {fields.map((field, idx) => (
                                            <TableCell
                                                key={idx}
                                                className="table-cell"
                                            >
                                                {field === 'status' ? (
                                                    <>
                                                        {
                                                            manageRulesTableHeader[
                                                                field
                                                            ]
                                                        }
                                                        <IconButton
                                                            onClick={() =>
                                                                handleSort(
                                                                    field
                                                                )
                                                            }
                                                        >
                                                            {sortBy === field &&
                                                                (sortOrder ===
                                                                'asc' ? (
                                                                    <ArrowUpwardIcon />
                                                                ) : (
                                                                    <ArrowDownwardIcon />
                                                                ))}
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={(event) =>
                                                                setAnchorElStatus(
                                                                    event.currentTarget
                                                                )
                                                            }
                                                        >
                                                            <FilterListIcon />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={
                                                                anchorElStatus
                                                            }
                                                            open={Boolean(
                                                                anchorElStatus
                                                            )}
                                                            onClose={() =>
                                                                setAnchorElStatus(
                                                                    null
                                                                )
                                                            }
                                                        >
                                                            {statusOptions.map(
                                                                (option) => (
                                                                    <MenuItem
                                                                        key={
                                                                            option
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={filterStatus.includes(
                                                                                option
                                                                            )}
                                                                            onChange={
                                                                                handleFilterStatus
                                                                            }
                                                                            value={
                                                                                option
                                                                            }
                                                                        />
                                                                        {capitalizeFirstLetter(
                                                                            option
                                                                        )}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Menu>
                                                    </>
                                                ) : field ===
                                                  'ruleAppliesTo' ? (
                                                    <>
                                                        {
                                                            manageRulesTableHeader[
                                                                field
                                                            ]
                                                        }
                                                        <IconButton
                                                            onClick={() =>
                                                                handleSort(
                                                                    field
                                                                )
                                                            }
                                                        >
                                                            {sortBy === field &&
                                                                (sortOrder ===
                                                                'asc' ? (
                                                                    <ArrowUpwardIcon />
                                                                ) : (
                                                                    <ArrowDownwardIcon />
                                                                ))}
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={(event) =>
                                                                setAnchorElRuleAppliesTo(
                                                                    event.currentTarget
                                                                )
                                                            }
                                                        >
                                                            <FilterListIcon />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={
                                                                anchorElRuleAppliesTo
                                                            }
                                                            open={Boolean(
                                                                anchorElRuleAppliesTo
                                                            )}
                                                            onClose={() =>
                                                                setAnchorElRuleAppliesTo(
                                                                    null
                                                                )
                                                            }
                                                        >
                                                            {ruleAppliesToOptions.map(
                                                                (option) => (
                                                                    <MenuItem
                                                                        key={
                                                                            option
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={filterRuleAppliesTo.includes(
                                                                                option
                                                                            )}
                                                                            onChange={
                                                                                handleFilterRuleAppliesTo
                                                                            }
                                                                            value={
                                                                                option
                                                                            }
                                                                        />
                                                                        {toUpperCase(
                                                                            option
                                                                        )}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Menu>
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            manageRulesTableHeader[
                                                                field
                                                            ]
                                                        }
                                                        <IconButton
                                                            onClick={() =>
                                                                handleSort(
                                                                    field
                                                                )
                                                            }
                                                        >
                                                            {sortBy === field &&
                                                                (sortOrder ===
                                                                'asc' ? (
                                                                    <ArrowUpwardIcon />
                                                                ) : (
                                                                    <ArrowDownwardIcon />
                                                                ))}
                                                        </IconButton>
                                                    </>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table-body">
                                    {filteredAndSortedRules
                                        .slice(page * 10, page * 10 + 10)
                                        .map((rule, rowIndex) => (
                                            <TableRow
                                                key={rowIndex}
                                                className={`table-row ${
                                                    rule.ruleType ===
                                                    C.RULE_FOR_TIER
                                                        ? 'tier-rule'
                                                        : 'point-rule'
                                                }`}
                                            >
                                                {fields.map((field, idx) => (
                                                    <TableCell
                                                        key={idx}
                                                        className="table-cell"
                                                    >
                                                        {field ===
                                                        C.RULE_PROPERTY_STATUS ? (
                                                            rule[field] ===
                                                            C.RULE_TYPE_PUBLISHED ? (
                                                                <span
                                                                    className="view-rule published"
                                                                    onClick={() =>
                                                                        rule.ruleType !==
                                                                            C.RULE_FOR_TIER &&
                                                                        openRuleEditor(
                                                                            C.RULE_TYPE_PUBLISHED,
                                                                            rule.loyaltyRuleId
                                                                        )
                                                                    }
                                                                >
                                                                    Published
                                                                </span>
                                                            ) : rule[field] ===
                                                              C.RULE_TYPE_DRAFT ? (
                                                                <span
                                                                    className="view-rule draft"
                                                                    onClick={() =>
                                                                        openRuleEditor(
                                                                            C.RULE_TYPE_DRAFT,
                                                                            rule.loyaltyRuleId
                                                                        )
                                                                    }
                                                                >
                                                                    Draft
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="view-rule expired"
                                                                    onClick={() =>
                                                                        openRuleEditor(
                                                                            C.RULE_TYPE_EXPIRED,
                                                                            rule.loyaltyRuleId
                                                                        )
                                                                    }
                                                                >
                                                                    Expired
                                                                </span>
                                                            )
                                                        ) : nonEmptyString(
                                                              rule[field]
                                                          ) ? (
                                                            rule[field]
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <div className="table-footer">
                                <button
                                    className="add-rule"
                                    onClick={() =>
                                        openRuleEditor(C.RULE_TYPE_NEW)
                                    }
                                >
                                    + Add Rule
                                </button>
                                <TablePagination
                                    component="div"
                                    count={filteredAndSortedRules.length}
                                    rowsPerPage={10}
                                    rowsPerPageOptions={[]}
                                    page={page}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </TableContainer>
                    </div>
                </Box>
            )}
        </div>
    );
};

export default ManageRules;
