export const APP_HOME_PAGE_TITLE = 'Home';
export const APP_CREATE_PROGRAM_WIZARD_TITLE = 'Create New Loyalty Program';
export const APP_MANAGE_TIERS_PAGE_TITLE = 'Manage Tiers';
export const APP_MANAGE_EVENTS_PAGE_TITLE = 'Manage Event Activities';
export const APP_MANAGE_RULES_PAGE_TITLE = 'Manage Rules';
export const APP_HELP_PAGE_TITLE = 'Help';

export const LOYALTY_PROGRAM_STATUS_DRAFT = 'DRAFT';
export const LOYALTY_PROGRAM_STATUS_PUBLISHED = 'PUBLISHED';
export const LOYALTY_PROGRAM_STATUS_NA = 'NOT_AVAILABLE';

export const TIER_PROPERTY_STATUS = 'status';
export const TIER_TYPE_NEW = 'NEW';
export const TIER_TYPE_DRAFT = 'DRAFT';
export const TIER_TYPE_PUBLISHED = 'PUBLISHED';
export const TIER_ACTION_CREATE = 'CREATE';
export const TIER_ACTION_UPDATE = 'UPDATE';
export const TIER_ACTION_DELETE = 'DELETE';

export const RULE_PROPERTY_OPERATION = 'operation';
export const RULE_PROPERTY_OPERATION_EDIT = 'EDIT';
export const RULE_PROPERTY_USER_ACTIVITY = 'Consumer';
export const RULE_PROPERTY_USER_TIER = 'USER TIER';
export const RULE_PROPERTY_STATUS = 'status';
export const RULE_TYPE_NEW = 'NEW';
export const RULE_TYPE_DRAFT = 'DRAFT';
export const RULE_TYPE_PUBLISHED = 'PUBLISHED';
export const RULE_TYPE_EXPIRED = 'EXPIRED';
export const RULE_FOR_POINT = 'POINT';
export const RULE_FOR_TIER = 'TIER';
export const RULE_EDITOR_CREATE_PROGRAM = 'ORIGIN_CREATE_PROGRAM';
export const RULE_EDITOR_MANAGE_PROGRAM = 'ORIGIN_MANAGE_PROGRAM';

export const RULE_INPUT_DISABLED = [RULE_TYPE_PUBLISHED, RULE_TYPE_EXPIRED];

export const RULE_ACTION_COMPLETED = 'COMPLETED';

export const EVENT_PROPERTY_STATUS = 'status';
export const EVENT_TYPE_NEW = 'NEW';
export const EVENT_TYPE_DRAFT = 'DRAFT';
export const EVENT_TYPE_PUBLISHED = 'PUBLISHED';

export const POINT_SOURCE_TYPE_ACTIVE = 'ACTIVE';

export const REGEX_POINT_SOURCE_NAME = /^(?!\s+$)[\p{L}\p{N} _\-]{1,50}$/u;
export const REGEX_POINT_SOURCE_MAXPOINTS = /^[1-9]\d{0,4}$/;
export const REGEX_TIER_NAME = /^(?!\s+$)[\p{L}\p{N} _\-]{1,50}$/u;
export const REGEX_TIER_DESCRIPTION = /^(?!\s+$)[\p{L}\p{N} _@#*$\-\[\]()!]{1,200}$/u;
export const REGEX_TIER_POINTS = /^[0-9]\d{0,4}$/;
export const REGEX_TIER_RANKING = /^[1-9]\d{0,2}$/;
export const REGEX_RULE_DESCRIPTION = /^(?!\s+$)[\p{L}\p{N} _@#*$\-\[\]()!]{1,200}$/u;
export const REGEX_RULE_USER_POINTS = /^[0-9]\d{0,4}$/;
export const REGEX_RULE_EVENT_ATTRIBUTE = /^[a-zA-Z0-9 _-]*$/;
export const RULE_FREQUENCY_VALUES = ['DAILY'];
