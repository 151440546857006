import { setSessionStorage } from '../services/storageUtils';

const sessionStorageMiddleware = (store) => (next) => (action) => {
    const result = next(action);
    const {auth, ...stateToStore} = store.getState();

    setSessionStorage('reduxState', JSON.stringify(stateToStore));
    return result;
};

export default sessionStorageMiddleware;
