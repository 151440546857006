import { API } from 'aws-amplify';
import { API_NAME, getConfig, validateResponse, handleError } from './apiUtils';

export async function getPointSourceList(market, category, brand) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/point-source`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function getPointSource(market, category, brand, pointSourceId) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/point-source/${pointSourceId}`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function createPointSource(market, category, brand, body) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/point-source`;
    return getConfig(body)
        .then((config) => API.post(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

export async function updatePointSource(
    market,
    category,
    brand,
    pointSourceId,
    body
) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/point-source/${pointSourceId}`;
    return getConfig(body)
        .then((config) => API.put(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}
