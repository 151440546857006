import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppBar,
    Box,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import theme from '../../styles';
import MarketDropdown from './MarketDropdown';
import CategoryDropdown from './CategoryDropdown';
import BrandDropdown from './BrandDropdown';
import { signOut } from '../../services/authUtils';
import { setStep } from '../../store/actions/programActions';
import { authSetUser } from '../../store/actions/authActions';
import { toggleSideBar } from '../../store/actions/navActions';
import { Link, useNavigate } from 'react-router-dom';

import PMILogo from '../../resources/images/pmi-logo.svg';

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { auth, navigation } = useSelector((state) => state);
    const { user } = auth;
    const { hasSideBar, openSideBar, currentComponent } = navigation;

    const {
        markets = [],
        market = null,
        categories = [],
        category = null,
        brands = [],
        brand = null,
    } = user || {};

    const handleMarketChange = useCallback(
        (market) => {
            if (user?.setMarket(market)) {
                dispatch(authSetUser(user));
                dispatch(setStep(0));
                navigate('/');
            }
        },
        [dispatch, navigate, user]
    );
    const handleCategoryChange = useCallback(
        (category) => {
            if (user?.setCategory(category)) {
                dispatch(authSetUser(user));
                dispatch(setStep(0));
                navigate('/');
            }
        },
        [dispatch, navigate, user]
    );
    const handleBrandChange = useCallback(
        (brand) => {
            if (user?.setBrand(brand)) {
                dispatch(authSetUser(user));
                dispatch(setStep(0));
                navigate('/');
            }
        },
        [dispatch, navigate, user]
    );
    const handleLogout = () => {
        signOut();
    };

    return (
        <Box className="app-header">
            <Toolbar>
                <img src={PMILogo} alt="Philip Morris International" />
                <Typography variant="h6" className="app-title">
                    Loyalty Admin Portal
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        ml: 'auto',
                    }}
                >
                    <Link to="/help" style={{ textDecoration: 'none' }}>
                        <Typography
                            variant="h6"
                            sx={{ minWidth: 100 }}
                            color={theme.palette.primary.main}
                        >
                            Help
                        </Typography>
                    </Link>
                    <Tooltip title="Log Out">
                        <IconButton
                            size="small"
                            sx={{ ml: 2 }}
                            color={theme.palette.primary.main}
                            onClick={handleLogout}
                        >
                            <LogoutOutlinedIcon className="app-icon" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
            <AppBar
                position="static"
                style={{
                    background:
                        'linear-gradient(90deg, #003DA5 2.6%, #46DB8C 100%)',
                }}
            >
                <Toolbar>
                    {hasSideBar && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() =>
                                dispatch(toggleSideBar(!openSideBar))
                            }
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        {currentComponent}
                    </Typography>

                    <div className="nav-dropdown-container">
                        <MarketDropdown
                            markets={markets}
                            market={market}
                            onChange={handleMarketChange}
                        />
                        <CategoryDropdown
                            categories={categories}
                            category={category}
                            onChange={handleCategoryChange}
                        />
                        <BrandDropdown
                            brands={brands}
                            brand={brand}
                            onChange={handleBrandChange}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Header;
