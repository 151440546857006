import { Auth, Hub } from 'aws-amplify';
import { isString } from './typeUtils';
import { UserData } from './UserData';

export function getUserData(token) {
    return new UserData(token);
}

async function handleError(err) {
    if (isString(err)) {
        if (err === 'No current user') {
            dispatchMessage('NO_USER_SESSION');
            throw new Error('NO_USER_SESSION');
        }
        throw new Error(err);
    }

    if (
        err?.code === 'NotAuthorizedException' &&
        err?.message === 'Refresh Token has expired'
    ) {
        dispatchMessage('USER_SESSION_EXPIRED');
        throw new Error('USER_SESSION_EXPIRED');
    }

    throw err;
}

export async function getIdToken() {
    return Auth.currentSession()
        .then((session) => `Bearer ${session.getIdToken().getJwtToken()}`)
        .catch(handleError);
}

export async function signIn() {
    return Auth.federatedSignIn().catch(handleError);
}

export async function signOut() {
    return Auth.signOut().catch(handleError);
}

export async function getCurrentUser() {
    return Auth.currentAuthenticatedUser()
        .then((user) => getUserData(user.signInUserSession.idToken.payload))
        .catch(handleError);
}

export function addAuthListener(listener) {
    Hub.listen('auth', listener);
    Hub.listen('custom', listener);
}

export function removeAuthListener(listener) {
    Hub.remove('auth', listener);
    Hub.remove('custom', listener);
}

export function dispatchMessage(event, data = undefined) {
    Hub.dispatch('custom', { event, data });
}
