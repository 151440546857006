import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import HomePage from '../pages/homePage/HomePage';

function AuthRequired() {
    const { loading, user } = useSelector((state) => state.auth);

    if (loading) return <HomePage />;

    if (user && user.hasAccess) return <Outlet />;

    return <Navigate to="/" />;
}

export default AuthRequired;
