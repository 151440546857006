// User Data class
import { Email } from './Email';

export class UserData {
    _claims;

    email;
    markets;
    market;
    categories;
    category;
    brands;
    brand;

    hasAccess;

    constructor(token) {
        this._claims = {};
        this._processToken(token);

        this.email = new Email(token?.email);
        this.markets = Object.keys(this._claims).sort();
        this.market = null;
        this.categories = null;
        this.category = null;
        this.brands = null;
        this.brand = null;
    }

    setMarket(market) {
        if (this.market && this.market === market) return false;
        this.market = this.markets.includes(market)
            ? market
            : this.markets[0] || null;
        this.categories = Object.keys(this._claims[this.market]);
        this.category = this.categories[0];
        this.brands = this._claims[this.market][this.category];
        this.brand = this.brands[0];

        return true;
    }

    setCategory(category) {
        if (this.category && this.category === category) return false;
        this.category = this.categories.includes(category)
            ? category
            : this.categories[0] || null;

        this.brands = this._claims[this.market][this.category];
        this.brand = this.brands[0];

        return true;
    }

    setBrand(brand) {
        if (this.brand && this.brand === brand) return false;
        this.brand = this.brands.includes(brand)
            ? brand
            : this.brands[0] || null;

        return true;
    }

    _processToken(token) {
        for (const key in token) {
            if (key.startsWith('APP:')) {
                const [, market, category] = key.split(':');
                const brands = token[key].split(',');

                if (!this._claims[market]) {
                    this._claims[market] = {};
                }

                if (!this._claims[market][category]) {
                    this._claims[market][category] = [];
                }

                this._claims[market][category].push(...brands);
            }
        }
        this.hasAccess = Object.keys(this._claims).length !== 0;
    }
}
