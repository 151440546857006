import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';

import SideNav from '../../common/SideNav';
import TierDetails from './TierDetails';
import {
    hasSideBar,
    toggleSideBar,
    updateCurrentComponent,
} from '../../../store/actions/navActions';
import {
    showLoading,
    hideLoading,
} from '../../../store/actions/loadingActions';
import { notifyError } from '../../../store/actions/notificationActions';
import { getTierList } from '../../../services/api/tierApi';
import { formatDate } from '../../../services/formatUtils';
import { Email } from '../../../services/Email';
import * as C from '../../../services/constants';

const manageTiersTableHeader = {
    status: 'Status',
    name: 'Tier Name',
    description: 'Tier Description',
    updatedTs: 'Published Date',
    updatedBy: 'Publisher EID',
};

const ManageTiers = () => {
    const dispatch = useDispatch();
    const fields = Object.keys(manageTiersTableHeader);

    const { auth } = useSelector((state) => state);
    const { market, category, brand } = auth.user;
    const [tiers, setTiers] = useState([]);
    const [tier, setTier] = useState({});
    const [showTier, setShowTier] = useState(false);
    const [highestRank, setHighestRank] = useState(0);
    const [type, setType] = useState('new');
    const [page, setPage] = useState(0);

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const openTier = (status, tierId = null) => {
        if (status === C.TIER_TYPE_PUBLISHED) {
            setType(C.TIER_TYPE_PUBLISHED);
            setTier(tiers.find((tier) => tier.loyaltyTierId === tierId));
            setShowTier(true);
        } else if (status === C.TIER_TYPE_DRAFT) {
            setType(C.TIER_TYPE_DRAFT);
            setTier(tiers.find((tier) => tier.loyaltyTierId === tierId));
            setShowTier(true);
        } else if (status === C.TIER_TYPE_NEW) {
            setType(C.TIER_TYPE_NEW);
            setTier(null);
            setShowTier(true);
        } else {
            setShowTier(false);
            setTier(null);
        }
    };
    const closeTier = () => {
        setShowTier(false);
    };
    useEffect(() => {
        dispatch(updateCurrentComponent(C.APP_MANAGE_TIERS_PAGE_TITLE));
        dispatch(hasSideBar(true));
        dispatch(toggleSideBar(false));

        dispatch(showLoading());
        getTierList(market, category, brand)
            .then((response) => {
                const existingTiers = response.data.map((tier) => ({
                    loyaltyTierId: tier.loyaltyTierId,
                    name: tier.name,
                    description: tier.description,
                    points: tier.points,
                    ranking: tier.ranking,
                    status: tier.status,
                    updatedTs: formatDate(tier.updatedTs),
                    updatedBy: new Email(tier.updatedBy).user,
                }));
                setTiers(existingTiers);
                const topRank = Math.max(
                    ...response.data.map((tier) => tier.ranking)
                );
                setHighestRank(topRank);
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to fetch tiers.'));
            })
            .finally(() => dispatch(hideLoading()));
    }, [showTier]);
    return (
        <div className="component-container-root">
            <SideNav />
            <Box className="manage-tiers-container component-container">
                <p className="instruction">
                    View and manage the tiers of the loyalty program. Go to '
                    <Link to="/help">Help page</Link>' for information on how to
                    manage.
                </p>
                <div className="manage-tiers">
                    <TableContainer className="table-container">
                        <Table className="table">
                            <TableHead className="table-head">
                                <TableRow className="table-row">
                                    {fields.map((field, idx) => (
                                        <TableCell
                                            key={idx}
                                            className="table-cell"
                                        >
                                            {manageTiersTableHeader[field]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                                {tiers
                                    .slice(page * 10, page * 10 + 10)
                                    .map((tier, rowIndex) => (
                                        <TableRow
                                            key={rowIndex}
                                            className="table-row"
                                        >
                                            {fields.map((field, colIndex) => (
                                                <TableCell
                                                    key={colIndex}
                                                    className="table-cell"
                                                >
                                                    {field ===
                                                    C.TIER_PROPERTY_STATUS ? (
                                                        tier[field] ===
                                                        C.TIER_TYPE_PUBLISHED ? (
                                                            <span
                                                                className="view-tier published"
                                                                onClick={() =>
                                                                    openTier(
                                                                        C.TIER_TYPE_PUBLISHED,
                                                                        tier.loyaltyTierId
                                                                    )
                                                                }
                                                            >
                                                                published
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="view-tier draft"
                                                                onClick={() =>
                                                                    openTier(
                                                                        C.TIER_TYPE_DRAFT,
                                                                        tier.loyaltyTierId
                                                                    )
                                                                }
                                                            >
                                                                draft
                                                            </span>
                                                        )
                                                    ) : (
                                                        tier[field]
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <div className="table-footer">
                            <button
                                className="add-tier"
                                onClick={() => openTier(C.TIER_TYPE_NEW)}
                            >
                                + Add Tier
                            </button>
                            <TablePagination
                                component="div"
                                count={tiers.length}
                                rowsPerPage={10}
                                rowsPerPageOptions={[]}
                                page={page}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </TableContainer>
                </div>
            </Box>
            {showTier && (
                <TierDetails
                    status={showTier}
                    type={type}
                    tier={tier}
                    tiers={tiers}
                    onClose={closeTier}
                    highestRank={highestRank}
                />
            )}
        </div>
    );
};

export default ManageTiers;
