import React, { useMemo, useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { categoryLabels } from '../../services/labelUtils';

function CategoryDropdown({ categories, category, onChange }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const icon = open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

    const options = useMemo(() => {
        return categories.sort();
    }, [categories]);

    const hasOptions = options.length > 1;
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (category) => {
        setAnchorEl(null);
        onChange(category);
    };

    return !hasOptions ? (
        <Typography sx={{ pr: 1, mx: 1 }}>
            {categoryLabels[category]}
        </Typography>
    ) : (
        <>
            <Button
                onClick={handleOpen}
                endIcon={icon}
                sx={{
                    color: 'inherit',
                    textTransform: 'none',
                    mx: 1,
                }}
            >
                <Typography>{categoryLabels[category]}</Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {options.map((item) => (
                    <MenuItem key={item} onClick={() => handleClick(item)}>
                        {categoryLabels[item]}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default CategoryDropdown;
