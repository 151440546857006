import * as C from '../constants';

// loyalty program
export function getCreateLoyaltyProgramPayload(
    status = C.LOYALTY_PROGRAM_STATUS_DRAFT
) {
    return { status };
}
export function getUpdateLoyaltyProgramPayload(
    status = C.LOYALTY_PROGRAM_STATUS_PUBLISHED
) {
    return { status };
}

// loyalty point source
export function getCreatePointSourcePayload(name, maxPoints, status) {
    return { name, maxPoints, status };
}
export function getUpdatePonintSourcePayload(name, maxPoints, status) {
    return { name, maxPoints, status };
}

// loyalty tiers
export function getTierListPayload(tierList) {
    let tiers = tierList.map((tier) => {
        const action =
            tier.action !== C.TIER_ACTION_CREATE &&
            tier.action !== C.TIER_ACTION_DELETE
                ? C.TIER_ACTION_UPDATE
                : tier.action;

        return {
            loyaltyTierId: tier.loyaltyTierId,
            name: tier.name,
            description: tier.description,
            points: parseInt(tier.points),
            ranking: tier.ranking,
            status: tier.status,
            action: action,
        };
    });
    return { tiers };
}

export function getTierPayload(
    name,
    description,
    points,
    ranking,
    status = 'DRAFT'
) {
    // console.log(ranking);
    return {
        name,
        description,
        points: parseInt(points),
        ranking: parseInt(ranking),
        status,
    };
}

// loyalty rules
export function getCreateRulePayload(
    description,
    condition,
    action,
    ruleType = 'POINT',
    status = 'DRAFT',
    namespace = 'CONSUMER'
) {
    return {
        description,
        condition,
        action,
        ruleType,
        status,
        namespace,
    };
}
export function getUpdateRulePayload(
    description,
    condition,
    action,
    ruleType = 'POINT',
    status = 'DRAFT'
) {
    return {
        description,
        condition,
        action,
        ruleType,
        status,
    };
}
