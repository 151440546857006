// labels

export const marketLabels = {
    ID: 'INDONESIA',
    PH: 'PHILIPINES',
};
export const categoryLabels = {
    CC: 'CONVENTIONAL CIGARETTES',
};
export const brandLabels = {
    MARLBORO: 'MARLBORO',
    MAGNUM: 'MAGNUM',
};
