import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    hasSideBar: false,
    openSideBar: false,
    currentComponent: 'Home',
};

const navReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.NAVIGATION_HAS_SIDEBAR: {
            return {
                ...state,
                hasSideBar: action.hasSideBar,
            };
        }
        case types.NAVIGATION_TOGGLE_SIDEBAR: {
            return {
                ...state,
                openSideBar: action.open,
            };
        }
        case types.NAVIGATION_UPDATE_COMP_NAME: {
            return {
                ...state,
                currentComponent: action.componentName,
            };
        }
        default: {
            return state;
        }
    }
};

export default navReducer;
