import { API } from 'aws-amplify';
import { API_NAME, getConfig, validateResponse, handleError } from './apiUtils';

/**
 *
 * @param {String} market Two character country code of the market/region
 * @param {String} category Business line or category of business
 * @param {String} brand Name of brand for which Loyalty Program is defined
 * @returns Loyalty Program Object if already created for the given market-category-brand combination.
 */
export async function getLoyaltyProgram(market, category, brand) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/loyalty-program`;
    return getConfig()
        .then((config) => API.get(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

/**
 *
 * @param {String} market Two character country code of the market/region
 * @param {String} category Business line or category of business
 * @param {String} brand Name of brand for which Loyalty Program is defined
 * @param {Object} body Request body {"status": "DRAFT"} for a new program}
 * @returns Loyalty Program Object with the newly created Program details
 */
export async function createLoyaltyProgram(market, category, brand, body) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/loyalty-program`;
    return getConfig(body)
        .then((config) => API.post(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}

/**
 *
 * @param {String} market Two character country code of the market/region
 * @param {String} category Business line or category of business
 * @param {String} brand Name of brand for which Loyalty Program is defined
 * @param {Object} body Request body {"status": "PUBLISHED"} for a existing program}
 * @returns
 */

export async function updateLoyaltyProgram(market, category, brand, programId, body) {
    const path = `/v1/loyaltyadmin/${market}/${category}/${brand}/loyalty-program/${programId}`;
    return getConfig(body)
        .then((config) => API.put(API_NAME, path, config))
        .then(validateResponse)
        .catch(handleError);
}
