// Navigation actions
import * as types from './actionTypes';

export const hasSideBar = (sideBar) => {
    return {
        type: types.NAVIGATION_HAS_SIDEBAR,
        hasSideBar: sideBar,
    };
};

export const toggleSideBar = (toggle) => {
    return {
        type: types.NAVIGATION_TOGGLE_SIDEBAR,
        open: toggle,
    };
};

export const updateCurrentComponent = (componentName) => {
    return {
        type: types.NAVIGATION_UPDATE_COMP_NAME,
        componentName: componentName,
    };
};
