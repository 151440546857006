// Program actions

import * as types from './actionTypes';

export const setStep = (step) => {
    return {
        type: types.PROGRAM_SET_STEP,
        step: step,
    };
};

export const assignTiers = (assignTiers) => {
    return {
        type: types.PROGRAM_ASSIGN_TIERS,
        assignTiers: assignTiers,
    };
};

export const storeProgramInfo = (programInfo) => {
    return {
        type: types.PROGRAM_STORE_INFO,
        programInfo,
    };
};
