// Root reducer

import { combineReducers } from 'redux';

import authReducer from './authReducer';
import programReducer from './programReducer';
import navReducer from './navReducer';
import notificationReducer from './notificationReducer';
import loadingReducer from './loadingReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    program: programReducer,
    navigation: navReducer,
    notification: notificationReducer,
    loading: loadingReducer,
});

export default rootReducer;
